
<template>
    <div>
        
         <v-snackbar v-model="snackbar" :top=true :color="color" :timeout=6000>
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-toolbar flat dense color="white" >
                     <v-toolbar-title>Synchronize Whatsapp Template</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                       style="margin-left:1000px"
                        class="btn-title ma-5 mt-8 center"
                        dark
                        color="indigo"
                        @click="synchtemplate()"
                    >
                        Synch Template</v-btn>
                </v-toolbar>

                <v-data-table :headers="headers" :items="whatsAppTemplate" class="elevation-1" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">                   
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
                            </v-text-field>
                            <v-spacer></v-spacer>
                            <span style="width: 20px"></span>
                        
                        </v-toolbar>
                    </template>

                      <template v-slot:item.active="{ item }">
       
                                <v-switch v-model="item.is_applicabletotpo" 
                                @change="activeis_applicabletotpo(item)" 
                                :labels="{checked:'On',unchecked:'Off'}"
                                ></v-switch>
                     </template> 
                </v-data-table>
    </div>
</template>

<script>
 import axios from 'axios'

export default {
 data: () => ({

      search: '',
       dialog: false,
        valid: false,
        iseditable : false,
         snackbar_msg: "",
        color: "",
        snackbar: false,
        whatsAppTemplate:[],

       headers: [
                {
                    text: 'Template Name',
                    align: 'left',
                    value: 'name',
                },
                    {
                    text: 'Message',
                    align: 'left',
                    value: 'body',
                },  
                 {
                    text: 'Is Applicable to TPO',
                    align: 'left',
                    value: 'active',
                },  
            ],
             editedIndex: -1,
             editedItem: {
                id:"",
                program:"",
                year:"",
                notice:"",
                no_of_days:"",
            },
             defaultItem: {
                id: '',
                program:"",
                year:"",
                notice:"", 
               no_of_days:"",
                   
            },
 }),
      mounted() {
            this.onLoad()
        },
        methods:{
                onLoad(){
                    axios
                    .post("/Whatsapp/whatsappSync")
                    .then((res) => {
                        //console.log("eror")
                        //console.log(res.data);
                        if (res.data.msg == "200") {
                        this.whatsAppTemplate = res.data.whatsAppTemplate;
                        //console.log("temlate");
                        //console.log(res.data.whatsAppTemplate)
                        //console.log(this.whatsAppTemplate)
                        }
                    });
                },
                    showSnackbar(clr, msg) {
                            this.snackbar = true;
                            this.color = clr;
                            this.snackbar_msg = msg;
                        },

                synchtemplate(){
                    axios
                    .post("/Whatsapp/whatsappSyncTemplate")
                    .then((res) => {
                        if (res.data.msg == "success") {
                             this.showSnackbar("green", "Template Synchronized successfully");
                        }else if (res.data.msg == "201") {
                             this.showSnackbar("#b71c1c", "Please contact admin to start service.");
                        }
                    });
                },

                activeis_applicabletotpo(item){
                    alert("hii")
                    const data={
                        item:item,
                    };
                    axios
                    .post("/Whatsapp/activeis_applicabletotpo",data)
                    .then((res) => {
                        if (res.data.code == "SUCCESS") {
                             this.showSnackbar("green", res.data.message);
                        }else if (res.data.msg == "201") {
                             this.showSnackbar("#b71c1c", "Something went wrong");
                        }
                    });
                },
                      
        }

}
</script>
